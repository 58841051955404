import { isEmptyString } from "@shared/Utils";
import { createElement, createElementWidthValue } from "@shared/Utils/element-helpers";

export const appendToSummary = (summary: HTMLElement, error: string) => {
  if (!summary) {
    return;
  }
  if (isEmptyString(summary.innerHTML)) {
    summary.innerHTML = "Hei. Vi fant disse manglene i skjemaet. ";
  }
  summary.innerHTML += `<span>${error}</span> `;
};

export const createSummaryHeading = (): Element => {
  const icon = createElement("i", "icon-wave");
  const el = createElement("h2", "validation-error-summary__heading");
  el.appendChild(icon);
  el.appendChild(createElementWidthValue("span", "Hei. Vi fant disse manglene i skjemaet. "));
  return el;
};

export const createErrorElement = (id: string, label: string, error: string): Element => {
  const el = createElement("li", "validation-error-summary__error");
  el.setAttribute("data-owner-id", id);

  const a = createElement("a");
  a.setAttribute("aria-roledescription", "Gå til ugyldig felt lenke");
  a.setAttribute("href", `#${id}`);
  a.innerHTML = label;
  el.appendChild(a);
  el.appendChild(createElementWidthValue("span", ` - `));
  el.appendChild(createElementWidthValue("span", error, "", "", "error-text"));
  return el;
};

export const getLabelText = (field: Element) => {
  const ancestor = field.closest(".form-input");
  const label = ancestor.querySelector("label");
  const text = label.innerHTML.replace('<span class="note">*</span>', "");
  return text;
};

export const hideElement = (element: HTMLElement) => {
  element.classList.add("hidden");
};

export const showElement = (element: HTMLElement) => {
  element.classList.remove("hidden");
};

export const markInputAsValid = (input: HTMLElement) => {
  input.classList.add("input--valid"); // needed for green checkmark
};

export const markInputAsTouched = (input: HTMLElement) => {
  input.setAttribute("data-touched", "true");
};

export const isInputTouched = (input: HTMLElement) => {
  return input.hasAttribute("data-touched");
};
