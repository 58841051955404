const trimKeyName = (name: string): string => {
  const arrName = name.split(".");

  return arrName.pop() as string;
};

const getElementValue = (element: HTMLElement): string | boolean => {
  if (element.getAttribute("type") === "checkbox") {
    return (element as HTMLInputElement).checked;
  }

  if (element instanceof HTMLInputElement && element.getAttribute("value-type") === "boolean") {
    return element.value === "True";
  }

  if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
    return element.value;
  }

  if (element instanceof HTMLSelectElement) {
    return element.value;
  }
  return element.innerHTML;
};

const getElementName = (el: HTMLElement): string => {
  const name = el.getAttribute("name") ?? el.id;
  return name;
};

export const getFormDataJson = (form: HTMLFormElement) => {
  const nameList: string[] = [];

  const elements = [
    ...form.querySelectorAll<HTMLElement>(`input:not([type="radio"]),input[type="radio"]:checked, textarea, select`),
  ];

  const result = Object.keys(elements).reduce((previous, current, index) => {
    // code remove duplicates, needed for checkbox code where there is added a input hidden for each checkbox
    const name = getElementName(elements[index] as HTMLElement);

    if (nameList.indexOf(name) === -1) {
      nameList.push(name);
      return { ...previous, [trimKeyName(name)]: getElementValue(elements[index] as HTMLElement) };
    }
    return { ...previous };
  }, {});

  return result;
};

export interface ErrorResponse {
  errorMessage: string;
  exception: unknown;
}
