export const createElement = (tagName: string, className = "", id = "", name = ""): HTMLElement => {
  const el = document.createElement(tagName);
  if (className) el.setAttribute("class", className);
  if (id) el.setAttribute("id", id);
  if (name) el.setAttribute("name", name);
  return el;
};

export const createElementWidthValue = (
  tagName: string,
  value: string,
  className = "",
  id = "",
  name = "",
): HTMLElement => {
  const el = createElement(tagName, className, id, name);
  if (tagName === "input") {
    (el as HTMLInputElement).value = value;
  } else {
    el.innerHTML = value;
  }

  return el;
};
