﻿import { isEmptyString } from "@lyse-as/ice-style/ts/00-atoms/00-global/Utils";
import { createElement, createElementWidthValue } from "@shared/Utils/element-helpers";
import {
  appendToSummary,
  createErrorElement,
  createSummaryHeading,
  getLabelText,
  hideElement,
  isInputTouched,
  markInputAsTouched,
  markInputAsValid,
  showElement,
} from "./input-validation-helpers";

interface ValidationResult {
  error: string;
  errorState: boolean;
}

const showErrorSummary = (errorSummary: Element, errorSummaryChild: Element, input: Element, error: string) => {
  if (!errorSummaryChild) {
    return;
  }

  if (isEmptyString(errorSummaryChild.innerHTML)) {
    errorSummaryChild.appendChild(createSummaryHeading());
    const summaryList = createElement("ul", "validation-error-summary__error-list");
    errorSummaryChild.appendChild(summaryList);
    errorSummaryChild.appendChild(
      createElementWidthValue(
        "p",
        "Korriger og fyll inn manglende informasjon for å fortsette. Du kan klikke på lenkene i feilmeldingen for å gå direkte til feltet",
      ),
    );

    errorSummary.classList.remove("hidden");
  }

  const list = errorSummaryChild.querySelector(".validation-error-summary__error-list");
  list.appendChild(createErrorElement(input.id, getLabelText(input), error));
};

export const prepareValidationSummary = (errorSummary: Element): Element => {
  if (!errorSummary) return;

  errorSummary.classList.add("hidden");
  let summaryChild = errorSummary.querySelector(".validation-error-summary__content");
  if (!summaryChild || summaryChild == null) {
    summaryChild = createElement("div", "validation-error-summary__content");

    errorSummary.appendChild(summaryChild);
  } else {
    summaryChild.innerHTML = "";
  }

  return summaryChild;
};

const updateErrorSummary = (form: HTMLFormElement) => {
  const validationEl = [...form.querySelectorAll(".validation-error-summary__error")];
  const filtered = validationEl.filter((x: Element) => {
    const el = document.getElementById(x.getAttribute("data-owner-id"));
    return el.classList.contains("input--invalid") || el.classList.contains("select--invalid");
  });

  if (filtered.length > 0) {
    const container = document.querySelector(".validation-error-summary__error-list");
    container.innerHTML = "";

    filtered.forEach((x) => {
      const el = document.getElementById(x.getAttribute("data-owner-id"));
      x.querySelector("[name='error-text']").innerHTML = el.getAttribute("data-current-error");
      container.appendChild(x);
    });
  } else {
    document.querySelector(".validation-error-summary").classList.add("hidden");
  }
};

export const validateForm = (form: HTMLFormElement) => {
  let valid = true;

  const inputElements = form.querySelectorAll(".input, .select");
  // used in Simple leadform block as Visual-Hidden
  const summary = form.querySelector<HTMLElement>("[name='summary']");
  if (summary) summary.innerHTML = "";

  const errorSummary = form.querySelector<Element>("[name='error-summary']");
  const errorSummaryChild = prepareValidationSummary(errorSummary);

  inputElements.forEach((input) => {
    const res = validateTextInput(input.getAttribute("id"));
    if (!res.errorState) {
      appendToSummary(summary, res.error);
      showErrorSummary(errorSummary, errorSummaryChild, input, res.error);
      valid = false;
    }
  });

  return valid;
};

const errorSummaryIsOpen = (form: HTMLElement): boolean => {
  const errorSummary = form.querySelector<Element>("[name='error-summary']");
  return errorSummary && !errorSummary.classList.contains("hidden");
};

export const serializeForm = (form: HTMLFormElement) => {
  const formData = new FormData(form);

  return Object.fromEntries(formData);
};

function getErrorContainerForInput(input: HTMLElement): HTMLElement {
  const container = input.closest(".form-input");
  let errorContainer = container.querySelector<HTMLElement>(".error-text");
  if (!errorContainer) {
    errorContainer = document.querySelector<HTMLElement>(`[data-valmsg-for="${input.id}"]`);
  }
  return errorContainer;
}

const showValidationErrorForInput = (input: HTMLElement, errorText: string) => {
  const tagName = input.tagName.toLocaleLowerCase();
  input.classList.remove(`${tagName}--valid`);
  input.setAttribute("aria-invalid", "true");
  input.classList.add(`${tagName}--invalid`);
  const errorContainer = getErrorContainerForInput(input);

  input.setAttribute("data-current-error", errorText);
  const errorHtml = `<i class='input-validation-icon'></i> ${errorText}`;
  errorContainer.innerHTML = errorHtml;
  errorContainer.classList.add("show");
  showElement(errorContainer);
};

function clearValidationErrorForInput(input: HTMLElement) {
  const tagName = input.tagName.toLocaleLowerCase();
  input.removeAttribute("aria-invalid");
  input.classList.remove(`${tagName}--invalid`);
  const errorContainer = getErrorContainerForInput(input);
  if (errorContainer) {
    errorContainer.innerHTML = "";
    errorContainer.classList.remove("show");

    hideElement(errorContainer);
  }
}

function validateTextInput(id: string): ValidationResult {
  const input = document.getElementById(id) as HTMLInputElement;
  clearValidationErrorForInput(input);

  // validate required
  const required = input.getAttribute("data-val-required");
  if (required && !input.value) {
    showValidationErrorForInput(input, required);
    return { error: required, errorState: false };
  }

  // validate regex
  const regexfirstPattern = input.getAttribute("data-val-regex-firstpattern");
  if (regexfirstPattern && !isEmptyString(input.value) && !input.value.match(regexfirstPattern)) {
    const errorText = input.getAttribute("data-val-regex-first");
    showValidationErrorForInput(input, errorText);
    return { error: errorText, errorState: false };
  }

  // validate regex
  const regexPattern = input.getAttribute("data-val-regex-pattern");
  if (regexPattern && !isEmptyString(input.value) && !input.value.match(regexPattern)) {
    const errorText = input.getAttribute("data-val-regex");
    showValidationErrorForInput(input, errorText);
    return { error: errorText, errorState: false };
  }

  // validate date
  const datatype = input.getAttribute("data-type");
  if (datatype === "date") {
    // Dates like "31. February" pass regex validation.
    // This logic complements regex validation.
    const dateParts = input.value.split(".");
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);
    const daysInMonths = [31, year % 4 ? 28 : 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (day > daysInMonths[month]) {
      const errorText = input.getAttribute("data-val-regex");
      showValidationErrorForInput(input, errorText);
      return { error: errorText, errorState: false };
    }

    if (new Date() < new Date(year, month, day)) {
      showValidationErrorForInput(input, "Fødselsdato skal ikke være i framtiden.");
      return { error: "Fødselsdato skal ikke være i framtiden.", errorState: false };
    }
  }

  markInputAsValid(input);
  return { error: "", errorState: true };
}

function validateRadioButtonGroup(groupName: string) {
  const radioButtons = [...document.getElementsByName(groupName)];
  const firstRadio = radioButtons[0]; // they have mostly the same attributes, use the first one

  clearValidationErrorForInput(firstRadio);

  // validate required
  const required = firstRadio.getAttribute("data-val-required");
  if (required) {
    const checked = radioButtons.some((input: HTMLInputElement) => {
      return input.checked;
    });
    if (!checked) {
      showValidationErrorForInput(firstRadio, required);
      return false;
    }
  }

  return true;
}

function revalidateTextInput(input: HTMLElement) {
  if (isInputTouched(input)) {
    validateTextInput(input.id);
  }
}

export function setupUnobtrusiveValidation(form: HTMLFormElement) {
  form.querySelectorAll(".input, .select").forEach((input: HTMLElement) => {
    input.addEventListener("blur", function () {
      validateTextInput(input.id);
      markInputAsTouched(input);

      if (errorSummaryIsOpen(form)) {
        updateErrorSummary(form);
      }
    });

    input.addEventListener("keyup", function () {
      revalidateTextInput(input);
    });
  });

  form.querySelectorAll('input[type="radio"]').forEach((input: HTMLElement) => {
    // Hmm, should we use blur in addition to change to hold it consistent with text-inputs?
    // Is it logical to show validation errors when user just tabs through the form?
    input.addEventListener("change", function () {
      validateRadioButtonGroup(input.getAttribute("name"));
    });
  });
}
