import { BusinessUnit } from "@shared/Utils";
import { pushToDataLayer } from "../data/data-layer.facade";
import { getBusinessUnit } from "@shared/Utils/analytics-utils";

export type GA4FormEventBase = {
  event: "formSubmission";
  business_unit: BusinessUnit;
};

export type GA4NewsLetterEvent = GA4FormEventBase & {
  form_type: "form_signup";
  form_name: "newsletter_signup";
};

export type GA4LeadFormEvent = GA4FormEventBase & {
  form_type: string;
  form_name: string;
};

export type GA4ContactFormEvent = GA4FormEventBase & {
  form_type: "contact";
  form_name: "customer_service_form_submitted";
  form_product: string;
  form_subject: string;
  form_call_user: "yes" | "no";
};

export type GA4OwnerChangeFormEvent = GA4FormEventBase & {
  form_type: string;
  form_name: string;
};

export type GA4FormStartEvent = {
  event: "formStart";
  form_type: string;
  form_name: string;
  business_unit: BusinessUnit;
};

const createGA4NewsLetterEvent = (): GA4NewsLetterEvent => ({
  event: "formSubmission",
  form_type: "form_signup",
  form_name: "newsletter_signup",
  business_unit: getBusinessUnit(),
});

const createGA4LeadFormEvent = (formType: string, formName: string): GA4LeadFormEvent => ({
  event: "formSubmission",
  form_type: formType,
  form_name: formName,
  business_unit: getBusinessUnit(),
});

const createGA4ContactFormEvent = (product: string, subject: string, canBeCalled: string): GA4ContactFormEvent => ({
  event: "formSubmission",
  form_type: "contact",
  form_name: "customer_service_form_submitted",
  form_product: product,
  form_subject: subject,
  form_call_user: canBeCalled === "yes" ? "yes" : "no",
  business_unit: getBusinessUnit(),
});

export const sendGANewsLetterEvent = (): void => {
  const event = createGA4NewsLetterEvent();
  pushToDataLayer(event);
};

export const sendGALeadFormEvent = (formType: string, formName: string): void => {
  const event = createGA4LeadFormEvent(formType, formName);
  pushToDataLayer(event);
};

export const sendGAContactFormEvent = (product: string, subject: string, canBeCalled: string): void => {
  const event = createGA4ContactFormEvent(product, subject, canBeCalled);
  pushToDataLayer(event);
};

export const sendGAFormStartEvent = (formType: string, formName: string): void => {
  const event: GA4FormStartEvent = {
    event: "formStart",
    form_type: formType,
    form_name: formName,
    business_unit: getBusinessUnit(),
  };
  pushToDataLayer(event);
};

export const setupFormStartEventTracking = (
  form: HTMLFormElement,
  trackFormStartEventFn: (form: HTMLFormElement) => void,
) => {
  let hasBeenTracked = false;
  const fields = form.querySelectorAll("input:not([type='hidden']), select, textarea");

  const handleUserInput = (event: Event) => {
    const element = event.target;
    if (!element) {
      return;
    }

    for (const field of fields) {
      field.removeEventListener("input", handleUserInput);
      field.removeEventListener("change", handleUserInput);
    }

    if (!hasBeenTracked) {
      trackFormStartEventFn(form);
      hasBeenTracked = true;
    }
  };
  for (const field of fields) {
    field.addEventListener("input", handleUserInput);
    field.addEventListener("change", handleUserInput);
  }
};
